<template>
  <transition name="loader">
    <div v-if="loading" class="cwp-loader">
      <div class="cwp-loader__spinner" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CwpLoader',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.cwp-loader {
  background-color: var(--background-color);
  position: relative;
  height: 400px;
  max-height: 400px;

  &--absolute {
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
  }

  &__spinner {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background-color: var(--body-text-color);
    border-radius: 100%;
    animation: cwp-loader 1s infinite $easeInOutQuad;
  }
}

@keyframes cwp-loader {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.loader-enter-active {
  transition: opacity 150ms linear, transform 150ms linear;
}
.loader-leave-active {
  transition: opacity 150ms linear;
}
.loader-enter,
.loader-leave-to {
  opacity: 0;
  height: 0;
}
</style>
